import mainStore from '@/store'
import creditNote from '@/store/main/orders/credit-note'
import {
  FLAT_PICKER,
  L_CURRENCY_MASK_INPUT,
  SELECT_CHILD_INPUT,
  SELECT_INPUT,
  TEXT_INPUT,
  TEXTAREA_INPUT,
} from '@/views/components/DynamicForm/constants'
import { getValueFromGivenObjectByKey } from '@core/utils/utils'
import mainConfig from '../../config'

// Get Order Statuses
const {
  ORDERS_STATUS_READY,
  ORDERS_STATUS_VOID,
} = mainConfig()

export default function config() {
  // Constants
  const MODULE_NAME = 'credit-note'
  const MODULE_NAME_ORDERS = 'orders'
  const MODULE_NAME_CLONE = 'cloneData'
  const TOTALS_FORM_TOTALS_COMPUTED_TABLE_TITLE = 'Totals'
  const TOTALS_FORM_TOTALS_COMPUTED_TABLE_TOTAL = 'TOTAL'

  const store = mainStore
  const model = creditNote

  const SUPPLIER_INFORMATION_TITLE = 'Supplier Information'
  const CREDIT_TITLE = 'Credit'

  const customerInformationFields = {
    customer_id: {
      label: 'Customer Name',
    },
    required_by: {
      type: FLAT_PICKER,
      label: 'Required by',
      hasOperationHours: true,
      options: {
        config: {
          'is-calendar-icon': true,
          minDate: new Date(),
        },
      },
    },
    billing_contact: {
      type: SELECT_CHILD_INPUT,
      label: 'Billing Contact',
      store: 'billingContact',
      rules: 'required',
      firstSelect: true,
      options: {
        label: 'fullName',
      },
    },
    billing_phone: {
      type: TEXT_INPUT,
      label: 'Billing Phone',
      placeholder: ' ',
    },
    billing_email: {
      type: TEXT_INPUT,
      label: 'Billing Email',
      placeholder: ' ',
    },
    billing_address: {
      type: TEXTAREA_INPUT,
      label: 'Billing Address',
      placeholder: ' ',
    },
    payment_term_id: {
      type: SELECT_INPUT,
      label: 'Payment Terms',
      store: 'paymentTermList',
      rules: 'required',
      fParams: { is_active: 1 },
      fSearchParams: { is_active: 1 },
      options: {
        label: 'label',
      },
    },
    linked_order_id: {
      type: TEXT_INPUT,
      label: 'Linked Order',
      // store: 'paymentTermList',
      // rules: 'required',
      options: {
        label: 'label',
      },
    },
    internal_account_notes: {
      type: TEXTAREA_INPUT,
      label: 'Internal Customer Notes ( not visible to the customer )',
      placeholder: ' ',
    },
    internal_order_reference: {
      type: TEXTAREA_INPUT,
      label: 'Internal Order Notes (not visible to the customer)',
      placeholder: 'Write notes here...',
    },
    external_order_reference: {
      type: TEXTAREA_INPUT,
      label: 'Order Notes ( visible to the supplier )',
      placeholder: 'Write notes here...',
    },
    attachments: {
      label: 'Attachments',
    },
  }

  const totalsFields = {
    total_order_notes: {
      type: TEXTAREA_INPUT,
      label: 'Credit Note Order Notes (visible to the customer)',
      placeholder: 'Write notes here...',
      options: {
        rows: 8,
      },
    },
  }

  const totalsComputedTable = [
    {
      title: 'Items',
      key: 'unit',
    },
    // {
    //   title: 'Other Charges',
    //   key: 'sale_other_charges',
    // },
    {
      title: 'Discounts',
      key: 'discount',
    },
    {
      title: 'Taxes',
      key: 'tax',
    },
  ]

  const creditModel = {
    unit: 1,
    price: 0,
    tax: 0,
    discount: 0,
    subtotal: 0,
  }
  const suggestionTableColumnsSupplier = [
    {
      key: 'cust_name_dba',
      label: 'Company',
    },
    {
      key: 'first_name',
      label: 'Contact',
      getValue: (value, data) => `
      ${getValueFromGivenObjectByKey(data, 'default_contact.first_name', '—')} 
      ${getValueFromGivenObjectByKey(data, 'default_contact.last_name', '')} 
      `,
    },
  ]

  const quoteStatusBar = [
    {
      icon: 'LQuoteIconFill',
      title: 'Credit Note',
      iconSize: '26',
    },
    {
      icon: 'LCreditCardIconFill',
      title: 'Paid',
      iconSize: '26',
    },
  ]

  const chargeModelFields = {
    name: {
      type: TEXT_INPUT,
      label: '',
    },
    unit: {
      type: TEXT_INPUT,
      label: '',
    },
    price: {
      type: L_CURRENCY_MASK_INPUT,
      label: '',
      placeholder: '$0',
    },
    subtotal: {
      type: L_CURRENCY_MASK_INPUT,
      label: '',
      placeholder: '$0',
    },
    discount: {
      type: L_CURRENCY_MASK_INPUT,
      label: '',
      placeholder: '$0',
    },
    tax: {
      type: L_CURRENCY_MASK_INPUT,
      label: '',
      placeholder: '$0',
    },
    totals: {
      type: L_CURRENCY_MASK_INPUT,
      label: '',
      placeholder: '$0',
    },
  }

  const tableColumnsCreditItems = [
    {
      key: 'actions',
      label: '',
      thStyle: { width: '5%' },
    },
    {
      key: 'name',
      label: 'Name',
      thStyle: { width: '56%' },
    },
    {
      key: 'units',
      label: 'Units',
      thStyle: { width: '8%', textAlign: 'center' },
    },
    {
      key: 'price',
      label: 'Price',
      thStyle: { width: '8%', textAlign: 'center' },
    },
    {
      key: 'subtotal',
      label: 'Subtotal',
      thStyle: { width: '8%', textAlign: 'center' },
    },
    {
      key: 'discount',
      label: 'Discount',
      thStyle: { width: '8%', textAlign: 'center' },
    },
    {
      key: 'tax',
      label: 'Taxes',
      thStyle: { width: '8%', textAlign: 'center' },
    },
    {
      key: 'totals',
      label: 'Total',
      thStyle: { width: '10%', textAlign: 'center' },
    },
  ]

  const orderInformationObjectForRender = [
    {
      key: 'customer.cust_name_dba',
      label: 'Supplier',
    },
    {
      key: 'none',
      label: 'Attachments',
    },
    {
      key: data => `
      ${getValueFromGivenObjectByKey(data, 'billing_contact.first_name', '—')} 
      ${getValueFromGivenObjectByKey(data, 'billing_contact.last_name', '')} 
      `,
      label: 'Billing Contact',
    },
    {
      key: data => getValueFromGivenObjectByKey(data, 'payment_term.label', '—'),
      label: 'Payment Terms',
    },

    {
      key: 'billing_contact.email',
      label: 'Billing Email',
    },
    {
      key: 'linked_order',
      label: 'Linked Order',
    },
    {
      key: 'billing_contact.phone_one',
      label: 'Billing Phone',
    },

    {
      key: 'internal_order_notes',
      label: 'Internal Order Notes ',
    },
    {
      key: data => (data?.billing_address ? `
      ${getValueFromGivenObjectByKey(data, 'billing_address.street', '—')}, 
      ${getValueFromGivenObjectByKey(data, 'billing_address.line2', '')},
      ${getValueFromGivenObjectByKey(data, 'billing_address.city', '')},
      ${getValueFromGivenObjectByKey(data, 'billing_address.state.code', '')} 
      ${getValueFromGivenObjectByKey(data, 'billing_address.zip', '')},
      ${getValueFromGivenObjectByKey(data, 'billing_address.country.three_letter', '')}
      ` : '—'),
      label: 'Billing Address',
    },
    {
      key: 'internal Customer notes',
      label: 'Internal Customer Notes ',
    },
  ]
  const ORDER_INFORMATION_TITLE = 'Order Information'
  const FORM_BUTTON_TITLE_VOID = 'Void'
  const FORM_BUTTON_TITLE_PRINT = 'Print'
  const FORM_BUTTON_TITLE_SAVE_AS_DRAFT = 'Save as Draft'
  const FORM_BUTTON_TITLE_SEND_BY_EMAIL = 'Send by Email'
  const FORM_BUTTON_TITLE_QUOTE_RECEIVED = 'Create Credit Note'
  const FORM_BUTTON_TITLE_MARK_PAID = 'Mark as Paid'

  const DIRECT_REQUEST_FOR_QUOTE_ACTION_VOID = 'void'
  const DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_DRAFT = 'draft'
  const CREATE_CREDIT_NOTE = 'create'
  const CREATE_CREDIT_NOTE_SENT_BY_EMAIL = 'send_email'
  const CREATE_CREDIT_NOTE_MARK_AS_PAID = 'paid'

  return {
    store,
    model,
    creditModel,
    MODULE_NAME,
    MODULE_NAME_ORDERS,
    MODULE_NAME_CLONE,
    SUPPLIER_INFORMATION_TITLE,
    TOTALS_FORM_TOTALS_COMPUTED_TABLE_TOTAL,
    TOTALS_FORM_TOTALS_COMPUTED_TABLE_TITLE,
    CREDIT_TITLE,
    customerInformationFields,
    tableColumnsCreditItems,
    totalsFields,
    totalsComputedTable,
    quoteStatusBar,
    suggestionTableColumnsSupplier,
    chargeModelFields,
    ORDER_INFORMATION_TITLE,
    FORM_BUTTON_TITLE_VOID,
    FORM_BUTTON_TITLE_PRINT,
    FORM_BUTTON_TITLE_SAVE_AS_DRAFT,
    FORM_BUTTON_TITLE_SEND_BY_EMAIL,
    FORM_BUTTON_TITLE_QUOTE_RECEIVED,
    DIRECT_REQUEST_FOR_QUOTE_ACTION_VOID,
    DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_DRAFT,
    CREATE_CREDIT_NOTE,
    CREATE_CREDIT_NOTE_SENT_BY_EMAIL,
    orderInformationObjectForRender,
    FORM_BUTTON_TITLE_MARK_PAID,
    CREATE_CREDIT_NOTE_MARK_AS_PAID,
  }
}
