export default {
  quote: {},
  quotes: [],
  creditItemsTable: {
    creditItems: [],
  },
  customerInformationForm: {
    attachments: [],
  },
  totalsForm: {},
  reRenderComponentKey: 0,
}
