export default {
  GET(state, data) {
    state.quote = { ...data }
  },
  LIST(state, data) {
    state.quotes = data
  },
  LIST_ATTACHMENTS(state, data) {
    state.customerInformationForm.attachments = data
  },
  SET_CUSTOMER_INFORMATION_FORM(state, data) {
    state.customerInformationForm = { ...data }
  },
  SET_CREDIT_ITEMS_TABLE(state, data) {
    state.creditItemsTable.creditItems = data
  },
  SET_TOTALS_FORM(state, data) {
    state.totalsForm = { ...data }
  },
  SET_RE_RENDER_COMPONENT_KEY(state) {
    state.reRenderComponentKey += 1
  },
}
