import crudActions from '@/libs/storeConstActions'
import { customActionGet } from '@core/utils/utils'
import axios from '@/libs/axios'

const endpoint = () => 'orders/credit-note'
const Actions = crudActions(endpoint)
const getCustomer = ({ }, { id, queryParams = {} }) => customActionGet(`customers/${id}`, queryParams)

const updateCreditNote = (_, payload) => {
  const id = payload instanceof FormData ? payload.get('id') : payload.id
  return axios.post(`${endpoint()}/${id}`, payload)
}

export default {
  ...Actions,
  getCustomer,
  updateCreditNote,
}
